<template>
  <div>
    <FormKit v-model="checkedOptions" :id="`field:${field.handle}`" type="hidden" :name="field.handle" :validation="field.required ? 'required':''"></FormKit>
    <FormKit type="checkbox" :label="field.label!" :options="[...preExistingOptions, ...customOptions]" :validation="field.required? 'required|min:1':'' "
             :outer-class="labelLeft ? 'grid grid-cols-2 gap-6 justify-between !mb-0 m:block': '' "
             :fieldset-class="labelLeft? 'contents': ''" label-class="ml-6" v-model="checkedOptions">
      <template #decorator>
        <custom-checkbox />
      </template>
    </FormKit>
    <div class=" gap-4 mt-2" :class="labelLeft ? 'grid grid-cols-2 m:grid-cols-1':''">
      <div class="minM:col-start-2">
        <div v-if="!extend" class="minM:pl-1 flex" @click="extend=true">
          <div class="w-5 h-5 flex justify-center items-center">
            <i class="fas fa-plus text-xl"></i>
          </div>
          <span class="ml-6 font-bold text-sm whitespace-nowrap">{{t("Eigene hinzufügen")}}</span>
        </div>
        <div class="flex gap-4" v-if="extend" @keydown.enter.prevent="addOption">

          <FormKit type="text" ignore input-class="text-xs" v-model="customOption"></FormKit>
          <div class="btn-primary py-2.5 px-5 h-min" @click.prevent="addOption">Hinzufügen</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup lang="ts">
import {Field_ExtendableEntries} from "../../../../gql/types/types";
import {FormKit} from "@formkit/vue";
import {computed, ref} from "vue";
import CustomCheckbox from "./includes/CustomCheckbox.vue";
import { VServices } from "@libVue/base";
import { TranslationService } from "../../../../services/translation.service";

const {field} = defineProps({
  field: {
    type: Object as () => Field_ExtendableEntries,
    required: true
  },
  formValues:{
    type:Object,
    required:true
  }
})

const SERVICES = VServices({translation: TranslationService})
const customOption = ref("")
const customOptions = ref<{ label: String, value: String }[]>([])
const extend = ref(false)
const checkedOptions = ref<(String | Number)[]>([])

const preExistingOptions = computed(() => {
  return field.entries?.map((entry) => {
    return {
      label: entry!.title,
      value: entry!.id,
      deletable: false
    }
  })
})

const addOption = () => {
  if (customOption.value) {
    customOptions.value.push({
      label: customOption.value,
      value: customOption.value,
      deletable: true
    })
    checkedOptions.value.push(customOption.value)
    customOption.value = ""
    extend.value = false
  }
}

const labelLeft = computed(() => {
  return field.labelPosition == 'verbb\\formie\\positions\\LeftInput'
})

const t = (key:string) => {
  return SERVICES.translation.t(key)
}
</script>

<style scoped>

</style>