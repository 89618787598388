import { Directive } from "vue";

export const vFixedbtn:Directive = {
    mounted(el:HTMLElement) {
        document.addEventListener('DOMContentLoaded', function() {
            const fixedButton = el
            const footer = document.querySelector('footer')!; // Adjust this selector to match your footer
        
            function adjustButtonPosition() {
                const footerRect = footer.getBoundingClientRect();
                if (footerRect.top <= window.innerHeight) {
                    fixedButton.style.bottom = `${footer.offsetHeight}px`;
                } else {
                    fixedButton.style.bottom = 'calc(2/24 * 100vh)'; // Or whatever your original bottom value was
                }
            }
        
            window.addEventListener('scroll', adjustButtonPosition);
            window.addEventListener('resize', adjustButtonPosition);
            adjustButtonPosition(); // Initial call
        });
    }
}