<script setup lang="ts">
import {FormKit} from '@formkit/vue'
import {VServices} from "@libVue/base";
import {TranslationService} from "../../services/translation.service";

const SERVICES = VServices({translation:TranslationService})
const {groups} = defineProps({
  groups:{
    type:Object,
    required:true
  },
  regions:{
    type:Object,
    required:true
  }
})

const t = (key: string) => {
  return SERVICES.translation.t(key)
}
</script>

<template>
  <div class="bg-white p-2.5 rounded-lg">
    <FormKit type="form" form-class="flex xs:block flex-wrap gap-4" action="/partner" :submit-label="t('Betrieb suchen')" :actions="false">
      <FormKit type="text" name="searchText" :placeholder="t('Wo')+'?'" icon="fas fa-search" outer-class="!mb-0 xs:!mb-4" :aria-label="t('Wo')+'?'">
        <template #prefix>
          <i class="fa-solid fa-location-dot pr-2.5 text-gray-500"></i>
        </template>
      </FormKit>
      <FormKit :placeholder="t('Region')" type="select" name="region" :options="regions as any" outer-class="!mb-0 xs:!mb-4" :aria-label="t('Region')" >
        <template #prefix>
          <i class="fas fa-calendar-days ml-3 mr-1.5 text-gray-500"></i>
        </template>
      </FormKit>
      <FormKit type="select" :options="groups as any" input-class="max-w-auto" outer-class="!mb-0 xs:!mb-4" name="zielgruppe" :placeholder="t('Zielgruppe')" :aria-label="t('Zielgruppe')">
        <template #prefix>
          <i class="fas fa-person ml-3 mr-1.5 text-gray-500"></i>
        </template>
      </FormKit>
      <button type="submit" class="btn-primary py-3 px-6 font-medium text-center m:w-full" ><i class="fal fa-magnifying-glass mr-2"></i>{{t('Betrieb suchen')}}</button>
    </FormKit>
  </div>
</template>

<style scoped lang="scss">

</style>