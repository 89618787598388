<script setup>
import {FormKitMessages} from '@formkit/vue';

import {ClassicEditor, Essentials, Autoformat, Bold,Italic, BlockQuote, Heading, Link, List, Paragraph, WordCount} from "ckeditor5"

import {Ckeditor} from '@ckeditor/ckeditor5-vue';
import "ckeditor5/ckeditor5.css"
import {onMounted, ref, watch} from 'vue';

const props = defineProps({
  context: Object,
});

const printf = function (text, ...args) {
  return text.replace(/{([0-9]+)}/g, function (match, index) {
    // check if the argument is there
    return typeof args[index] == 'undefined' ? match : args[index];
  });
};

const editorData = ref(props.context.value || '');

const toolbar = props.context.attrs?.toolbar || ['|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockquote', '|', 'undo', 'redo'];

const plugins = [
  Essentials,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Heading,
  Link,
  List,
  Paragraph,
];

if (props.context.maxWords) {
  plugins.push(WordCount);
}

const editorConfig = {
  plugins,
  toolbar,
  ui: {
    poweredBy: {
      position: 'none',
    },
  },
};

const wordProgress = ref({});
const wordCount = ref(0);

const radius = 17;
const circleCircumference = 2 * Math.PI * radius;

if (props.context.maxWords) {
  editorConfig.wordCount = {
    onUpdate: stats => {
      const charactersProgress = stats.words / props.context.maxWords * circleCircumference;
      const isLimitExceeded = stats.words > props.context.maxWords;
      const isCloseToLimit = !isLimitExceeded && stats.words > Math.min(props.context.maxWords * .8, props.context.maxWords - 5);
      const circleDashArray = Math.min(charactersProgress, circleCircumference);

      wordProgress.value = {
        dash: `${circleDashArray},${circleCircumference}`,
        isValid: !isLimitExceeded,
        count: stats.words,
        remaining: Math.max(0, props.context.maxWords - stats.words),
        isCloseToLimit,
      };
    },
  };
}

const editor = ClassicEditor;
const ckeditor = Ckeditor;

watch(editorData, (a) => {
  props.context.node.input(editorData.value);
}, { immediate: true });

onMounted(()=>{
  if(props.context.node.value){
    
    editorData.value = props.node.value;
  }
})
</script>

<template>
  <div :class="$style['editor']" :style="`--awc-wysiwyg-editor-height: ${props.context.attrs?.minHeight || 100}px`">
    <ckeditor v-model="editorData"
              :id="props.context.id"
              @blur="props.context.handlers.blur"
              :disabled="props.context.disabled"
              v-bind="$attrs"
              :config="editorConfig"
              :editor="editor"></ckeditor>

    <div :class="$style['hidden-textarea']">
      <textarea :name="props.context.node.name" v-model="editorData" tabindex="-1" readonly></textarea>
    </div>

    <FormKitMessages :node="props.context.node" :class="$style['formkit-messages']" class="formkit-messages"/>

    <div v-if="props.context.maxWords"
         :class="[ wordProgress.isCloseToLimit ? $style['almost-invalid'] : '', !wordProgress.isValid ? $style['invalid'] : '', $style['word-count-status'] ]">

      <svg viewBox="0 0 40 40" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <circle stroke="hsl(0, 0%, 93%)" stroke-width="3" fill="none" cx="20" cy="20" :r="radius"></circle>

        <circle :class=" $style['circle'] " stroke-width="3"
                :stroke-dasharray="wordProgress.dash"
                stroke-linecap="round" fill="none" cx="20" cy="20" :r="radius"></circle>

        <text :class=" $style['text'] "
              x="50%" y="50%"
              dominant-baseline="central"
              text-anchor="middle">{{ wordProgress.isValid ? '' : '-' }}{{ wordProgress.count }}
        </text>
      </svg>

      <span v-if="props.context.maxWordsLabel">
        {{ printf(props.context.maxWordsLabel, props.context.maxWords, wordProgress.remaining, wordProgress.count) }}
      </span>
    </div>

  </div>
</template>

<style module>

.hidden-textarea {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
}

.formkit-messages {
  padding: .5rem 1rem !important;
}

.formkit-messages + .word-count-status {
  display: none !important;
}
.editor {
  width: 100%;
  --ck-color-base-background: rgb(255 255 255 / 22%);
  --ck-color-toolbar-background: rgb(255 255 255 / 50%);
}

.editor :global(.ck-content) {
  min-height: var(--awc-wysiwyg-editor-height, 300px);
}

.editor :global(.ck-content ul ),
.editor :global(.ck-content ol ) {
  margin-left: 1.5rem;
}

.word-count-status {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-size: .9em;
}

.word-count-status svg {
  margin-right: .5rem;
  width: 1.5em;
}

.circle {
  stroke: hsl(202, 92%, 59%);
  transition: all 0.3s ease-in-out;
}

.almost-invalid .circle {
  stroke: hsl(0, 30%, 50%)
}

.invalid .circle {
  stroke: hsl(0, 80%, 50%)
}
</style>

<style lang="scss">
.ck-toolbar, .ck-editor__main{
  @apply bg-white
}
// this is used solely for the `powered by` logo.
.ck-body-wrapper {
  display: none;
}
</style>