/**
 * Webroot URL for folder ./web/
 * including trailing slash
 */
export const BASEURL: string = ( window as any ).BASEURL;
/**
 * Website home of current language
 * including trailing slash
 */
export const SITEURL: string = ( window as any ).SITEURL;

export const CRAFT_TRANSLATIONS: Record<string, string> = ( window as any ).CRAFT_TRANSLATIONS;

export const CRAFT_LANGUAGE: string = ( window as any ).CRAFT_LANGUAGE;