import { VBaseService, VServices } from '@libVue/base';

export type TOverlayHandle = 'none' | string;

export interface IOverlay {
  handle: TOverlayHandle;
  obj?: any;
}

export class OverlaysService extends VBaseService {
  private _handles: Array<string> = [
    'none'
  ];

  private readonly overlay: IOverlay = {
    handle: 'none',
    obj: undefined
  };

  public get obj(): any {
    if ( this.has() ) {
      return this.overlay.obj;
    }
    return undefined;
  }

  public has( handle?: TOverlayHandle ): boolean {
    return this.overlay.handle !== 'none' && (
      0 <= this._handles.indexOf( this.overlay.handle )
      && ( !handle || this.overlay.handle === handle )
    );
  }

  public show( handle: TOverlayHandle, obj?: any ): void {
    ( () => {
      const overlayEL = document.getElementsByClassName( handle )[ 0 ];
      overlayEL.classList.add( 'overlay-show' );
      this.overlay.handle = handle; this.overlay.obj = obj;

      this.updateDocBody();
    } )();
  }

  public hide( handle?: string ): void {
    console.log( 'hide()' );
    const overlayEL = document.getElementsByClassName( handle ? handle : this.overlay.handle )[ 0 ];

    if ( overlayEL ) {
      overlayEL.classList.remove( 'overlay-show' );
      this.overlay.handle = 'none';
      this.overlay.obj = undefined;

      this.updateDocBody();
    }
  }

  public toggle( handle: TOverlayHandle, obj?: any ): void {
    if ( this.has() ) {
      this.hide();
    } else {
      this.show( handle, obj );
    }
  }

  public register( handle: string ): string {
    if ( typeof handle !== 'string' || handle.length === 0 || handle === 'none' ) {
      throw new Error( 'Overlays service: Empty handle given' );
    } else if ( 0 <= this._handles.indexOf( handle ) ) {
      throw new Error( `Overlays service: Handle "${handle}" already registered` );
    }
    this._handles.push( handle );
    // console.log( `Overlay handle "${handle}" registered` );
    return handle;
  }

  private updateDocBody(): void {
    if ( this.overlay.handle === 'none' ) {
      document.documentElement.style.overflowY = 'auto';
      document.body.classList.remove( 'has-overlay' );
    } else {
      if ( !document.body.classList.contains( 'has-overlay' ) ) {
        document.body.classList.add( 'has-overlay' );
      }
      document.documentElement.style.overflowY = 'hidden';
    }

  }
}