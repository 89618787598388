/* eslint-disable @typescript-eslint/ban-types */
/**
 *  ===============================================================================================
 *     ____ _____ _   _          _______     __                 _____           _     _    _
 *    |  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *    | |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *    |  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *    | |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *    |____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *  ===============================================================================================
 * @author    BINARY one GmbH
 * @license Unlicensed
 */
import 'flowbite'
import '/src/scss/index.scss';
//in your `main.js` file
import { BASEURL, SITEURL } from '@libTs/cms.globals';

import {createApp, provide} from 'vue';
import './animations';

// Component imports

// Directive imports


// Service imports
import { OverlaysService } from './services/overlays.service';
import { CookieService } from './services/cookie.service';
import { vFocus } from './directives/v-focus';
import { vHeader } from './directives/v-header';
import { vSearch } from './directives/v-search';
import {NCarousel} from "naive-ui";
import {vMegamenubtn} from "./directives/v-megamenubtn";
import {DefaultApolloClient} from "@vue/apollo-composable";
import {FormKit, plugin} from "@formkit/vue";
import config from "./formkit.config"
import SmallSearchForm from "./components/forms/SmallSearchForm.vue";
import VueAkkordeon from "./components/Akkordeons/Akkordeon.vue";
import PartnerSearch from "./components/forms/PartnerSearch.vue";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client/core";
import FormieForm from "./components/forms/formie/FormieForm.vue";
import { vFixedbtn } from './directives/v-fixedbtn';

// -----------------------------------------------------------
// DEBUG output
const ISDEBUG = import.meta.env.DEV;

if (ISDEBUG) {
  console.group('Environment loaded');
  console.log(ISDEBUG);
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

const apolloClient = new ApolloClient({
  link: createHttpLink({uri:'/actions/graphql/api'}),

  cache: new InMemoryCache()
})

const app = createApp({
  setup(){
    provide(DefaultApolloClient, apolloClient)
  },
  data() {
    return {
      overlays: new OverlaysService(),
      cookie: new CookieService()
    };
  },
  provide() {
    return {
      overlays: this.overlays,
      cookie: this.cookie
    };
  },
  components: {
    SmallSearchForm,
    VueAkkordeon,
    PartnerSearch,
    NCarousel,
    FormieForm,
  },
  directives: {
    Focus: vFocus,
    Header: vHeader,
    Search: vSearch,
    Megamenubtn: vMegamenubtn,
    Fixedbtn: vFixedbtn
  }
});


app.use(plugin, config)
app.mount('#app');
