<script setup lang="ts">
import { Field_FileUpload, FieldInterface } from "../../../../gql/types/types";
import { FormKit } from "@formkit/vue";
import { VServices } from "@libVue/base";
import { TranslationService } from "../../../../services/translation.service";
import { ref, watch, onMounted } from "vue";

const SERVICES = VServices({translation:TranslationService})

const {field} = defineProps({
  field: {
    type: Object as () => Field_FileUpload,
    required: true
  },
  formValues:{
    type:Object,
    required:true
  }
})
const t = (key:string) =>{
  return SERVICES.translation.t(key)
}

const files = ref([]);

// watch(files, () => {
//   files.value = files.value.filter((file: { name: string, file: File | null }) => {
//     return file.file !== null && (typeof file.file === 'object' && Object.keys(file.file).length > 0);
//   });
// });
const removeFiles = () =>{
  files.value = []
}
</script>

<template>
  <FormKit
    v-model="files"
    multiple
    v-if="field"
    type="file"
    :id="`field:${field.handle}`"
    :label="field.label!"
    :name="field.handle!"
    :validation="field.required ? `required|length:1,${field.limitFiles}` : ''"
    accept=".pdf,.png,.jpg,.jpeg"
    inner-class="h-40"
  >
    <template #fileRemove>
      <div class="btn-primary py-6 px-3 h-min absolute right-4 z-50" v-if="files.length > 0" @click="removeFiles">{{ t('Entfernen') }}</div>
    </template>
  </FormKit>
</template>

<style lang="scss">
.formkit-noFiles{
  @apply justify-center;
}
</style>