<script setup lang="ts">
import {FormKit} from "@formkit/vue"
import {ref} from "vue"
import {Field_MultiLineText, FieldInterface} from "../../../../gql/types/types";

const {field} = defineProps({
  field: {
    type: Object as () => Field_MultiLineText,
    required: true
  },
  formValues:{
    type:Object,
    required:true
  }
})
const text = ref("")

</script>

<template>
<FormKit type="richtext" :id="`field:${field.handle}`" :label="field.label" :name="field.handle" :validation="field.required? 'required':'' " />
</template>

<style scoped lang="scss">

</style>