import {createInput, defaultConfig, defineFormKitConfig} from "@formkit/vue";
import {de, fr, en} from "@formkit/i18n"
import {applicationIcons, genesisIcons} from "@formkit/icons";
import {rootClasses} from "./formkit.theme";
import {createLocalStoragePlugin, createMultiStepPlugin} from "@formkit/addons";
import WYSIWYG from "./formKit/fields/WYSIWYG.vue";
import {requiredAsteriskPlugin} from "./formKit/plugins/required-asterisk.plugin";
import '@formkit/addons/css/multistep'
import jumpToFirstErrorPlugin from "./formKit/plugins/jump-to-first-error.plugin";

export default defaultConfig({
    locales:{de,fr,en},
    inputs:{
        "richtext": createInput(WYSIWYG, {
            props:["maxWords", "maxWordsLabel"]
        }),
    },
    locale: 'de',
    icons: {
        ...genesisIcons
    },
    config:{
        rootClasses
    },
    plugins:[
        requiredAsteriskPlugin,
        jumpToFirstErrorPlugin,
        createMultiStepPlugin(),
        createLocalStoragePlugin()
    ]
})