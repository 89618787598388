import { Directive } from 'vue';

export const vSearch: Directive = {
  mounted: (el: HTMLInputElement) => {
    el.addEventListener('input', () => {
      if (el.value.length > 0) {
        if (!el.nextElementSibling!.classList.contains('hidden')) {

          el.nextElementSibling!.classList.add('hidden');
        }
      } else {
        el.nextElementSibling!.classList.remove('hidden');
      }

    });
  }
};
