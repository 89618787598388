<script setup lang="ts">

</script>

<template>
  <div class="peer-focus:ring peer-checked:hidden border rounded text-xs px-0.5 border-black w-5 h-5 flex items-center justify-center">
    <i class="fas fa-check content-center text-primary-50"></i>
  </div>
  <div class="peer-focus:ring hidden peer-checked:flex items-center justify-center  text-white text-xs bg-primary-600 border w-5 h-5 rounded px-0.5 border-primary-600">

    <i class="fas fa-check  content-center"></i>
  </div>
</template>

<style scoped lang="scss">

</style>