import { VBaseService } from '@libVue/base';

export class CookieService extends VBaseService {

  public setVisited() {
    console.log('setVisited');

    const d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));
    const expires = 'expires='+ d.toUTCString();
    document.cookie = 'visited=1;' + expires + ';path=/';
  }

  public alreadyVisited(): boolean {
    if(this.getCookie('visited') == '1') {
      return true;
    }
    else {
      window.addEventListener('beforeunload', this.setVisited);

      return false;
    }
  }

  public getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

}