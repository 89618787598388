const jumpToFirstErrorPlugin = (node) => {
  // Only apply this plugin to the root form node
  if (node.props.type !== 'form') return;

  node.on('submit-invalid', () => {
    // Wait for the DOM to update
    setTimeout(() => {
      // Find the first error message
      const firstErrorEl = document.querySelector('.formkit-message[data-message-type="validation"]');
      if (!firstErrorEl) return;

      // Check if we're in a multi-step form
      const multiStepForm = node.children.find(child => child.props.type === 'multi-step');
      
      if (multiStepForm) {
        // Find which step contains the error
        const errorStep = multiStepForm.children.find(step => 
          step.el.contains(firstErrorEl)
        );
        
        if (errorStep) {
          // Set the active step
          multiStepForm.setStep(errorStep.name);
        }
      }

      // Scroll to the error
      firstErrorEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  });
};

export default jumpToFirstErrorPlugin;