import {VBaseService, VServices} from '@libVue/base';
import {CRAFT_TRANSLATIONS, SITEURL} from '@libTs/cms.globals';

export class TranslationService extends VBaseService {

    public t(key: string): string {
        try {
            if (CRAFT_TRANSLATIONS[key]) {
                return CRAFT_TRANSLATIONS[key];
            } else {
                // console.log(key)
                return key;
            }
        } catch (error) {
            // console.log("Missing Translation:", key)
            return key;
        }
    }
}