import {Directive} from "vue";

export const vMegamenubtn:Directive = {
    mounted(el){
        el.addEventListener('click', (ev:Event)=>{
           const active = el.classList.contains('megamenu-active')
            if(!active){
                el.classList.add('megamenu-active')
                el.innerHTML = '<i class="fas fa-xmark w-6"></i>'
            }
            else{

                el.classList.remove('megamenu-active')
                el.innerHTML = '<i class="fas fa-bars w-6"></i>'
            }
        })
    }
}