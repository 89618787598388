<script setup lang="ts">
import { gsap } from 'gsap'
import {onMounted, ref, watch} from 'vue'
import {VServices} from "@libVue/base";
import {TranslationService} from "../../services/translation.service";
import {FormKit, reset, submitForm} from "@formkit/vue";
import FilterAkkordeon from "../Akkordeons/FilterAkkordeon.vue";
import {request} from "graphql-request"
import {BASEURL} from "@libTs/cms.globals.js";
import gql from "graphql-tag";

//ts stuff
interface option {
  label: string,
  value: string
}

interface formValues {
  searchText: string,
  region: Number | Number[],
  zielgruppe: Number | Number[]
  sprache: Number | Number[],
  wohnsituation: Number | Number[],
  ausbildung: Number | Number[],
  spezialisierungen: Number | Number[],
  angebot: Number | Number[]
}

const SERVICES = VServices({translation: TranslationService})

//props
const {regionen, zielgruppen} = defineProps({
  regionen: {
    type: Array<option>,
    required: true
  },
  zielgruppen: {
    type: Array<option>,
    required: true
  },
  categoryGroups: {
    type: Array,
    required: true
  }
})

//vars
const advancedSearchOpen = ref(false)
const searchValues = ref({})
const graphqlUrl = BASEURL + '/actions/graphql/api'
const loading = ref(false)
const form = ref()
const partners = ref([])
const noFirstResults = ref(false)
const searchQuery = gql`
 query SearchQuery(
 $relatedEntries:[QueryArgument]=null, $searchTerm:String =null) {
  entries(section: "partner", relatedTo:$relatedEntries, search:$searchTerm, ) {
    id
    title
    url
    ... on partner_Entry {
      partnerbilder {
        url @transform(handle: "quarterSize")
      }
      verfuegbar
      untertitel
      wohnort
      kanton
      personengruppe {
        id
        title
        ... on personengruppe_Entry {
          shortname
        }
      }
      region {
        id
      }
    }
  }
}
`
const baseQuery = gql`
 query getPartner {
  entries(section: "partner"){
    id
    title
    url
    ... on partner_Entry{
      partnerbilder{
        url @transform(handle:"quarterSize")
        alt
      }
      verfuegbar
      untertitel
      wohnort
      kanton
      personengruppe{
        id
        title
        ... on personengruppe_Entry{
          shortname
        }
      }
      region{
        id
      }
      angebot{
        id
      }
      spezialisierungen{
        id
      }
      wohnsituation{
        id
      }
      ausbildung{
        id
      }
      sprache{
        id
      }
    }
  }
}

`
//methods
const t = (key: string) => {
  return SERVICES.translation.t(key)
}

const search = async (formValues: formValues) => {
  noFirstResults.value = false
  loading.value = true
  const searchText = formValues.searchText && formValues.searchText.length ? formValues.searchText : null
  const values = Object.values(formValues)
  const vars: any = {
    relatedEntries: values.flat().filter((v: any) => typeof v === 'number'),
    searchTerm: searchText
  }
  if (vars.relatedEntries.length > 0) {
    vars.relatedEntries = ['and', ...vars.relatedEntries]
  } else {
    delete vars.relatedEntries
  }

  const data: any = await request(graphqlUrl, searchQuery, vars)

  if (data && data.entries && data.entries.length) {
    partners.value = data.entries
    loading.value = false
  } else {
    noFirstResults.value = true
    await cleanSearch()
  }
}

watch(advancedSearchOpen, (val) => {
  if (val && matchMedia('(max-width:480px').matches) {
    document.documentElement.style.overflow = 'hidden'
  }
})

const cleanSearch = async () => {
  const data: any = await request(graphqlUrl, baseQuery)

  partners.value = data.entries
  loading.value=false;
}
onMounted(() => {
  loading.value = true
  const params = new URLSearchParams(window.location.search)
  const fields = ['searchText', 'region', 'zielgruppe']
  let hasParams = false

  fields.forEach(field => {
    if (params.has(field)) {
      const value = params.get(field)
      searchValues.value[field] = field === 'searchText' ? value : Number(value)
      hasParams = true
    }
  })


  if (hasParams) {
    submitForm('partnerSearch')
  } else {
    cleanSearch()
  }
})
const resetForm = (id:string)=>{
  reset(id)
  searchValues.value = []
  noFirstResults.value = false
  cleanSearch()
}

const showExtendedFilters = (el: HTMLElement, done: () => void) => {
  gsap.fromTo(el, 
    { x: '100%' },
    { 
      x: '0%', 
      duration: 0.3, 
      ease: 'power2.out',
      onComplete: done
    }
  )
}

const hideExtendedFilters = (el: HTMLElement, done: () => void) => {
  gsap.to(el, {
    x: '100%',
    duration: 0.3,
    ease: 'power2.in',
    onComplete: done
  })
}
</script>
<template>
  <div>
    <div class="bg-white p-6 gap-4 flex-wrap mb-12">
      <FormKit type="form" id="partnerSearch" form-class="flex flex-col flex-wrap" :actions="false" @submit="search"
               v-model="searchValues" ref="form">
        <div class="flex gap-4 flex-wrap">

          <FormKit type="search" :placeholder="t('Search')" name="searchText">
            <template #prefix>
              <i class="fas fa-magnifying-glass mr-2"></i>
            </template>
          </FormKit>
          <FormKit type="select" :placeholder="t('Region')" :options="regionen" name="region"></FormKit>
          <FormKit type="select" :placeholder="t('Zielgruppe')" :options="zielgruppen" name="zielgruppe"></FormKit>
          <button type="submit" class="btn-primary py-3 px-6 mb-4">{{ t('Anbieter finden') }}</button>
          
        </div>
        <div class="flex justify-end">
          <button type="button" class="cursor-pointer minS:mr-0 minS:ml-auto text-primary-600 hover:no-underline underline transition-all duration-300" @click="resetForm('partnerSearch')">
            {{ t('Filter zurücksetzen') }}
          </button>
          <div
              class="cursor-pointer ml-8 text-primary-600 hover:no-underline underline transition-all duration-300"
              @click="advancedSearchOpen = !advancedSearchOpen">
            {{ t('Erweiterte Suche') }}
          </div>
          <transition :css="false" @enter="showExtendedFilters" @leave="hideExtendedFilters">
              <div v-show="advancedSearchOpen"
                 class="fixed top-0 bottom-0 right-0 overflow-auto bg-white z-50 p-5 min-w-80 flex flex-col shadow"
                 ref="slideout">
              <div class="uppercase flex items-center whitespace-nowrap font-semibold">{{ t('Suche verfeinern') }} <span
                  @click="advancedSearchOpen = false" class="ml-auto mr-0 cursor-pointer"><i
                  class="ml-2 text-xl fa-solid fa-xmark"></i></span></div>
              <div class="mt-5">
                <FilterAkkordeon v-for="categoryGroup in categoryGroups">
                  <template #header>
                    <div class="text-gray-500 text-sm font-medium">{{ categoryGroup.label }}</div>
                  </template>
                  <template #content>
                    <FormKit type="checkbox" :options="categoryGroup.options" :name="categoryGroup.label.toLowerCase()"
                             label-class="text-sm font-medium"
                             inner-class="mr-2">
                      <template #decorator="props">
                        <div
                            class="peer-checked:hidden border rounded text-xs px-0.5 border-black w-5 h-5 flex items-center justify-center">
                          <i class="fas fa-check content-center"></i>
                        </div>
                        <div
                            class="hidden peer-checked:flex items-center justify-center  text-white text-xs bg-primary-600 border w-5 h-5 rounded px-0.5 border-primary-600">

                          <i class="fas fa-check  content-center"></i>
                        </div>
                      </template>
                    </FormKit>
                  </template>
                </FilterAkkordeon>
              </div>
              <div class="flex gap-4 mt-auto mb-0 pt-5">
                <button type="submit" class="btn-primary px-6 py-3" @click="advancedSearchOpen = false">
                  {{ t('Suche verfeinern') }}
                </button>
                <button type="reset" class="btn-secondary px-5 py-2.5 ml-auto mr-0 " @click="()=>{ resetForm('partnerSearch')}">
                  {{ t('Zurücksetzen') }}
                </button>
              </div>
            </div>
          </transition>
        </div>
      </FormKit>
    </div>

    <div class="text-center font-bold text-xl mb-12" v-if="noFirstResults">{{ t('Es wurden keine Ergebnisse gefunden mit den gewählten Filtern') }} <br> {{ t('Hier sind andere Partner die Sie interessieren könnten') }}</div>
    <div class=" grid grid-cols-3 l:grid-cols-2 m:grid-cols-1 gap-9">
      <template v-if="!loading">
        <div v-for="partner in partners" class="flex flex-col min-w-80 shadow rounded-xl">
          <img v-if="partner.partnerbilder.length" :src="partner.partnerbilder[0].url" :alt="partner.partnerbilder[0].alt"
               class="object-cover aspect-video object-center rounded-t-xl">
               <img v-else :src="`${BASEURL}/assets/placeholders/Platzhalter-Partner.png`" class="object-cover aspect-video object-center rounded-t-xl">
          <div class="p-6 bg-white flex flex-col h-full rounded-b-xl">
            <div class="mt-auto mb-0">
              <div class="text-2xl font-bold text-title-black">{{ partner.title }}</div>
              <div class="text-black leading-tight">{{ partner.untertitel }}</div>
              <div class="my-4 flex items-center"><i
                  class="fas fa-location-dot text-primary-600 mr-1 text-xs"></i>{{ partner.wohnort }}, {{ t('Kanton') }}
                {{ partner.kanton }}
              </div>
              <div class="flex flex-wrap gap-2">
                <div v-for="personengruppe in partner.personengruppe">
                  <div class="bg-primary-100 text-primary-800 text-xs font-medium px-2.5 py-0.5 rounded">
                    {{ personengruppe.shortname ?? personengruppe.title }}
                  </div>
                </div>
              </div>
              <div class="my-4 font-bold text-sm" :class="partner.verfuegbar ? 'text-primary-600':'text-red-600'">
                {{ partner.verfuegbar ? t('Verfügbar') : t('Nicht verfügbar') }}
              </div>
              <div class="mt-6">
                <a :href="partner.url"
                   class="btn-primary px-6 py-3 flex h-min items-center w-min whitespace-nowrap">{{ t('Mehr erfahren') }}
                  <i class="ml-2 content-center text-xl fas fa-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="loading" class="col-span-3 flex justify-center items-center">
        <div role="status" class="flex justify-center mt-10">
          <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600"
               viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"/>
            <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>