<script setup lang="ts">
import {ref} from "vue";

const isOpen = ref(false)
</script>
<template>
  <div>
    <div @click="isOpen = !isOpen">
      <div class="flex py-4">
      <slot name="header"></slot>
        <i class="ml-auto mr-0 fas fa-chevron-up" v-if="isOpen"></i>
        <i class="ml-auto mr-0 fas fa-chevron-down" v-else></i>
      </div>
    </div>
    <div v-show="isOpen">
      <slot name="content"></slot>
    </div>
  </div>
</template>


<style scoped>

</style>