import Entries from "./Entries.vue";
import ExtendableEntries from "./ExtendableEntries.vue";
import FileUpload from "./FileUpload.vue";
import MultiLineText from "./MultiLineText.vue";
import SingleLineText from "./SingleLineText.vue";
import Checkboxes from "./Checkboxes.vue";
import Address from "./Address.vue";
import Dropdown from "./Dropdown.vue";
import Agree from "./Agree.vue";
import Email from "./Email.vue";
import Group from "./Group.vue";
import Number from "./Number.vue"
import Phone from "./Phone.vue";

export default {
    Entries,
    ExtendableEntries,
    FileUpload,
    MultiLineText,
    SingleLineText,
    Checkboxes,
    Address,
    Dropdown,
    Email,
    Agree,
    Number,
    Phone,

    Group
}