<template>
  <div class="">
<div  @click="open = !open"  class="p-5 border-b border-gray-200 cursor-pointer">
  <slot name="title" :active="open">
  </slot>
</div>
    <div v-if="open" :class="containerClasses" class="p-5 border-b border-gray-200">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
 import {ref} from "vue";

 const {containerClasses} = defineProps({
   containerClasses:{
      type: String,
   }
 })
 const open = ref(false)

</script>

<style scoped>

</style>